export const html = {
    "100": {
        "path": "/html/email_open.html",
        "title": "Email Open [100]",
    },
    "110": {
        "path": "/html/email_open_cross_tag.html",
        "title": "Email Open Cross Tag [110]",
    },
    "120": {
        "path": "/html/aim_tag_on_click.html",
        "title": "Tag on Click [120]",
    },
    "130": {
        "path": "/html/aim_tag_on_partner_query_parameter.html",
        "title": "Tag on Partner Query Param [130]",
    },
    "140": {
        "path": "/html/set_cookie.html",
        "title": "Cookie [140]",
    },
    "141": {
        "path": "/html/set_local_storage.html",
        "title": "Localstorage [141]",
    },
    "142": {
        "path": "/html/set_window_name.html",
        "title": "Window Name [142]",
    },
    "180": {
        "path": "/html/transition_v2_to_v4.html",
        "title": "Transition v2 to v4 [180]",
    },
    "200": {
        "path": "/html/aim/reader/page_init.html",
        "title": "AIM.init Only [200]",
    },
    "201": {
        "path": "/html/aim/reader/page_init_without_api_key.html",
        "title": "AIM.init Only w/o API Key [201]",
    },
    "202": {
        "path": "/html/aim/reader/page_init_onload_pageview.html",
        "title": "AIM.init w/onload_pageview:false [202]",
    },
    "203": {
        "path": "/html/aim/reader/page_init_onload_pageview_true.html",
        "title": "AIM.init w/onload_pageview:true [203]",
    },
    "204": {
        "path": "/html/aim/reader/page_callback.html",
        "title": "AIM.init w/callback fx [204]",
    },
    "205": {
        "path": "/html/aim_reader_page_with_partner_tracker.html",
        "title": "AIM.init w/partners [205]",
    },
    "220": {
        "path": "/html/aim/reader/page_ondetect.html",
        "title": "AIM.ondetect [220]",
    },
    "221": {
        "path": "/html/aim/reader/page_ondetect_with_fetch.html",
        "title": "AIM.fetch [221]",
    },
    "222": {
        "path": "/html/aim/reader/page_init_pageview.html",
        "title": "AIM.pageview [222]",
    },
    "223": {
        "path": "/html/aim/reader/get_session.html",
        "title": "AIM.getSessionId [223]",
    },
    "224": {
        "path": "/html/aim/reader/page_init_version.html",
        "title": "AIM.version [224]",
    },
    "225": {
        "path": "/html/aim_reader_page_promise.html",
        "title": "AIM.promise [225]",
    },
    "226": {
        "path": "/html/aim/reader/page_ondetect_no_aim_global.html",
        "title": "AIM_orgId without AIM Global [226]",
    },
    "227": {
        "path": "/html/aim/reader/page_ondetect_with_error.html",
        "title": "AIM.ondetect with callback error [227]",
    },
    "300": {
        "path": "/html/aim/reader/page_multi_global_with_org_id.html",
        "title": "AIM Global w/AIM_orgId [300]",
    },
    "301": {
        "path": "/html/aim/reader/page_multi_org_id_with_global.html",
        "title": "AIM_orgId w/AIM Global [301]",
    },
    "302": {
        "path": "/html/aim/reader/page_multi_org_id_with_org_id.html",
        "title": "AIM_orgId w/AIM_orgId [302]",
    },
    "380": {
        "path": "/html/aim/reader/page_multi_global_with_global.html",
        "title": "AIM Global w/AIM Global [380]",
    },
    "381": {
        "path": "/html/aim/reader/page_multi_global_with_attempted_detection.html",
        "title": "AIM Global w/attempted detection [381]",
    },
    "400": {
        "path": "/html/aim_reader_slim.html",
        "title": "Slim Reader (TrendMD) [400]",
    },
    "410": {
        "path": "/html/aim_reader_slim_signal.html",
        "title": "Slim Signal (TrendMD) [410]",
    },
    "500": {
        "path": "/html/aim/reader/delete_all_tags.html",
        "title": "Delete All Tags [500]",
    },
    "600": {
        "path": "/html/parallel-versions/with_no_commands.html",
        "title": "Parallel Versions with no commands [600]",
    },
    "601": {
        "path": "/html/parallel-versions/with_alternate_datalayer_and_no_commands.html",
        "title": "Parallel Versions with Alternate DataLayer Name and no commands [601]",
    },
    "602": {
        "path": "/html/parallel-versions/with_alternate_datalayer_with_pageview.html",
        "title": "Parallel Versions with Alternate DataLayer Name and Pageview [602]",
    },
    "603": {
        "path": "/html/parallel-versions/with_pageview.html",
        "title": "Parallel Versions with Pageview [603]",
    },
    "604": {
        "path": "/html/parallel-versions/with_pageview_override.html",
        "title": "Parallel Versions with Pageview Override [604]",
    },
    "620": {
        "path": "/html/parallel-versions/with_signal_only.html",
        "title": "Parallel Versions with Signal only [620]",
    },
    "621": {
        "path": "/html/parallel-versions/with_pageview_and_signal.html",
        "title": "Parallel Versions with Pageview and Signal [621]",
    },
    "630": {
        "path": "/html/parallel-versions/with_multiple_readers.html",
        "title": "Parallel Versions with Multiple Readers [630]",
    },
    "700": {
        "path": "/html/custom_pageview.html",
        "title": "Custom Pageview Test [700]",
    },
    "701": {
        "path": "/html/custom_pageview_modern_build_only.html",
        "title": "Custom Pageview Test modern build only [701]",
    },
    "800": {
        "header": "AIM Tag with GTM",
        "path": "/html/integration_examples/gtm.html",
        "title": "AIM Tag with GTM [800]",
    },
    "801": {
        "header": "AIM Tag with GTM and Custom Dimensions",
        "path": "/html/integration_examples/gtm_custom_dimensions.html",
        "title": "AIM Tag with GTM and Custom Dimensions [801]",
    },
    "802": {
        "header": "AIM Tag with GTM Modern Build",
        "path": "/html/integration_examples/gtm_modern_build.html",
        "title": "AIM Tag with GTM Modern Build [802]",
    },
    "803": {
        "header": "AIM Tag with GTM Modern Build and Custom Dimensions",
        "path": "/html/integration_examples/gtm_modern_build_custom_dimensions.html",
        "title": "AIM Tag with GTM Modern Build and Custom Dimensions [803]",
    },
    "810": {
        "header": "AIM Tag with Adobe Analytics",
        "path": "/html/integration_examples/adobe_analytics.html",
        "title": "AIM Tag with Adobe Analytics [810]",
        "modernBuildOnly": false,
    },
    "811": {
        "header": "AIM Tag with Adobe Analytics Modern Build",
        "path": "/html/integration_examples/adobe_analytics_modern_build.html",
        "title": "AIM Tag with Adobe Analytics Modern Build [811]",
        "modernBuildOnly": true,
    },
    "820": {
        "header": "AIM Tag with Google Universal Analytics",
        "path": "/html/integration_examples/google_universal_analytics.html",
        "title": "AIM Tag with Google Universal Analytics [820]",
        "modernBuildOnly": false,
    },
    "821": {
        "header": "AIM Tag with Google Universal Analytics Modern Build",
        "path": "/html/integration_examples/google_universal_analytics_modern_build.html",
        "title": "AIM Tag with Google Universal Analytics Modern Build [821]",
        "modernBuildOnly": true,
    },
    "830": {
        "header": "AIM Tag with Google Analytics 4",
        "path": "/html/integration_examples/google_analytics4.html",
        "title": "AIM Tag with Google Analytics 4 [830]",
        "modernBuildOnly": false,
    },
    "831": {
        "header": "AIM Tag with Google Analytics 4 Modern Build",
        "path": "/html/integration_examples/google_analytics4_modern_build.html",
        "title": "AIM Tag with Google Analytics 4 Modern Build [831]",
        "modernBuildOnly": true,
    },
    "900": {
        "header": "AIM Tag with SSO Lite on Button Click",
        "path": "/html/sso/aim_tag_with_sso_lite_on_button_click.html",
        "title": "AIM Tag with SSO Lite on Button Click [900]",
    },
    "1000": {
        "header": "Prebid.js with Appnexus",
        "path": "/html/prebid/gtm_appnexus.html",
        "title": "GTM with Appnexus Integration [1000]",
    },
    "1010": {
        "header": "Prebid.js with DeepIntent",
        "path": "/html/prebid/gtm_deepintent.html",
        "title": "GTM with DeepIntent Integration [1010]",
    },
    "1020": {
        "header": "Prebid.js with Pubmatic",
        "path": "/html/prebid/gtm_pubmatic.html",
        "title": "GTM with Pubmatic Integration [1020]",
    },
};
