import React from "react";
import {
  Container,
  Divider,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RoomIcon from "@mui/icons-material/Room";
import LaunchIcon from "@mui/icons-material/Launch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CodeIcon from "@mui/icons-material/Code";
import { legacyReaderMultiPages } from "../pages/legacy_reader_multi_pages";
import { legacyReaderSinglePages } from "../pages/legacy_reader_single_pages";
import { parallelReaderPages } from "../pages/parallel_reader_pages";
import { prebidPages } from "../pages/prebid_pages";
import { adobeAnalyticsIntegrationPages } from "../pages/adobe_analytics_integration_pages";
import { googleAnalytics4IntegrationPages } from "../pages/google_analytics4_integration_pages";
import { googleUniversalAnalyticsIntegrationPages } from "../pages/google_univeral_analytics_integration_pages";
import { gtmIntegrationPages } from "../pages/gtm_integration_pages";
import { gtmModernBuildIntegrationPages } from "../pages/gtm_modern_build_integration_pages";
import { ssoLitePages } from "../pages/sso_lite_pages";
import { routes } from "../routes";

export default function Dashboard() {
  return (
    <Container>
      <Typography align="center" variant="h4">
        AIM Web QA Suite
      </Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Tag Writing</b>
          </Typography>
          <List>
            <ListItem button component="a" href={routes.html[100].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[100].title} />
            </ListItem>
            <ListItem button component="a" href={routes.html[110].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[110].title} />
            </ListItem>
            <ListItem button component="a" href={routes.html[120].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <OpenInNewIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[120].title} />
            </ListItem>
            <ListItem button component="a" href={routes.html[130].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <OpenInNewIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[130].title} />
            </ListItem>
            <ListItem button component="a" href={routes.html[140].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <RoomIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[140].title} />
            </ListItem>
            <ListItem button component="a" href={routes.html[141].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <LaunchIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[141].title} />
            </ListItem>
            <ListItem button component="a" href={routes.html[142].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <LaunchIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[142].title} />
            </ListItem>
            <ListItem button component="a" href={routes.html[180].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <ExpandMoreIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[180].title} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Tag Reading (Single)</b>
          </Typography>
          <List>
            {legacyReaderSinglePages.map(({ route }) => (
              <ListItem key={route.path} button component="a" href={route.path} sx={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <CodeIcon />
                </ListItemIcon>
                <ListItemText primary={route.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Tag Reading (Multi)</b>
          </Typography>
          <List>
            {legacyReaderMultiPages.map(({ route }) => (
              <ListItem key={route.path} button component="a" href={route.path} sx={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <CodeIcon />
                </ListItemIcon>
                <ListItemText primary={route.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">
            <b>Tag Reading (Slim)</b>
          </Typography>
          <List>
            <ListItem button component="a" href={routes.html[400].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[400].title} />
            </ListItem>
            <ListItem button component="a" href={routes.html[410].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[410].title} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Parallel Versions</b>
          </Typography>
          <List>
            {parallelReaderPages.map(({ route }) => (
              <ListItem key={route.path} button component="a" href={route.path} sx={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <CodeIcon />
                </ListItemIcon>
                <ListItemText primary={route.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Custom Pageviews</b>
          </Typography>
          <List>
            <ListItem button component="a" href={routes.html[700].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[700].title} />
            </ListItem>
            <ListItem button component="a" href={routes.html[701].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[701].title} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Integration Examples</b>
          </Typography>
          <List>
            {[...gtmIntegrationPages, ...gtmModernBuildIntegrationPages, ...adobeAnalyticsIntegrationPages, ...googleUniversalAnalyticsIntegrationPages, ...googleAnalytics4IntegrationPages].map(({ route }) => (
              <ListItem key={route.path} button component="a" href={route.path} sx={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <CodeIcon />
                </ListItemIcon>
                <ListItemText primary={route.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">
            <b>Single Sign On</b>
          </Typography>
          <List>
            {ssoLitePages.map(({ route }) => (
              <ListItem key={route.path} button component="a" href={route.path} sx={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <CodeIcon />
                </ListItemIcon>
                <ListItemText primary={route.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        {prebidPages.map(({ route }) => (
          <Grid item xs={12} sm={6} md={3} key={route.path} sx={{ borderRight: 1, borderColor: "divider" }}>
            <Typography variant="h6">
              <b>{route.header}</b>
            </Typography>
            <List>
              <ListItem button component="a" href={route.path} sx={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <CodeIcon />
                </ListItemIcon>
                <ListItemText primary={route.title} />
              </ListItem>
            </List>
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">
            <b>Prebid.js</b>
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Static</b>
          </Typography>
          <List>
            <ListItem button component="a" href={routes.static[1100].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.static[1100].title} />
            </ListItem>
            <ListItem button component="a" href={routes.static[1200].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.static[1200].title} />
            </ListItem>
            <ListItem button component="a" href={routes.static[1210].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.static[1210].title} />
            </ListItem>
            <ListItem button component="a" href={routes.static[1220].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.static[1220].title} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}></Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Deprecated</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Deprecated</b>
          </Typography>
          <List>
            <ListItem button component="a" href={routes.html[225].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[225].title} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Deprecated</b>
          </Typography>
          <List>
            <ListItem button component="a" href={routes.html[380].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[380].title} />
            </ListItem>
            <ListItem button component="a" href={routes.html[381].path} sx={{ display: "flex", alignItems: "center" }}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={routes.html[381].title} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ borderRight: 1, borderColor: "divider" }}>
          <Typography variant="h6">
            <b>Deprecated</b>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
