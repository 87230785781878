import * as qs from 'qs';

// tslint:disable-next-line:no-any
export const appendQueryParams = (url: string, params?: {[key: string]: any} | undefined) => {
    const [, path, search] = url.match(/^([^?]*)\??([\w\W]*)$/) as string[];
    const query = {
        ...qs.parse(search),
        ...(params || {}),
    };
    return `${path}?${qs.stringify(query)}`;
};

export default appendQueryParams;
