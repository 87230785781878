import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Segment } from "semantic-ui-react";

type ExternalCodeBoxProps = {
    testId: string;
    externalScript: string;
    externalScriptNoModule?: string;
    inlineScript: string;
    modified?: boolean;
    runScript?: boolean;
};

export const ExternalCodeBox = ({
    testId,
    externalScript,
    externalScriptNoModule = externalScript + "&target=ie11",
    inlineScript,
    modified = false,
    runScript = true,
}: ExternalCodeBoxProps) => {
    return (
        <Segment style={{ "overflow": "auto", "whiteSpace": "nowrap" }} >
            {runScript && <HelmetProvider>
                <Helmet>
                    <script async noModule={true} src={externalScriptNoModule}></script>
                    <script async type="module" src={externalScript}></script>
                </Helmet>
            </HelmetProvider>}
            <pre data-testid={testId} data-modified={modified}>
                {`<script async nomodule src="${externalScriptNoModule}"></script>`}
                <br />
                {`<script async type="module" src="${externalScript}"></script>`}
                <br />
                {`<script>`}
                {inlineScript}
                {`</script>`}
            </pre>
        </Segment>
    );
};
export default ExternalCodeBox;
