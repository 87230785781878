import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import EnvironmentSelector from '../components/EnvironmentSelector';
import { ParallelSnippetTestPage } from "../components/html/ParallelSnippetTestPage";
import { GTMSnippetTestPage } from "../components/html/prebid/GTMSnippetTestPage";
import { AdobeAnalyticsIntegrationPage } from "../components/html/integration_examples/AdobeAnalyticsIntegrationPage";
import { GoogleAnalytics4IntegrationPage } from "../components/html/integration_examples/GoogleAnalytics4IntegrationPage";
import { GoogleUniversalAnalyticsIntegrationPage } from "../components/html/integration_examples/GoogleUniversalAnalyticsIntegrationPage";
import { GTMIntegrationPage } from "../components/html/integration_examples/GTMIntegrationPage";
import { GTMModernBuildIntegrationPage } from "../components/html/integration_examples/GTMModernBuildIntegrationPage";
import { AIMTagSSOLitePage } from "../components/html/sso/AIMTagSSOLitePage";
import { ReaderSnippetTestPage } from "../components/html/ReaderSnippetTestPage";
import { SlimReader400 } from '../components/SlimReader400';
import SlimSignal410 from '../components/SlimSignal410';
import TagDeleting500 from '../components/TagDeleting500';
import { TagReading700 } from "../components/TagReading700";
import { TagReading701 } from "../components/TagReading701";
import TagWriting100 from '../components/TagWriting100';
import TagWriting110 from '../components/TagWriting110';
import { TagWriting120 } from '../components/TagWriting120';
import { TagWriting130 } from "../components/TagWriting130";
import { TagWriting140 } from '../components/TagWriting140';
import TagWriting141 from '../components/TagWriting141';
import TagWriting142 from '../components/TagWriting142';
import TagWriting180 from '../components/TagWriting180';
import { legacyReaderMultiPages } from "../pages/legacy_reader_multi_pages";
import { legacyReaderSinglePages } from "../pages/legacy_reader_single_pages";
import { parallelReaderPages } from "../pages/parallel_reader_pages";
import { prebidPages } from "../pages/prebid_pages";
import { adobeAnalyticsIntegrationPages } from "../pages/adobe_analytics_integration_pages";
import { googleAnalytics4IntegrationPages } from "../pages/google_analytics4_integration_pages";
import { googleUniversalAnalyticsIntegrationPages } from "../pages/google_univeral_analytics_integration_pages";
import { gtmIntegrationPages } from "../pages/gtm_integration_pages";
import { gtmModernBuildIntegrationPages } from "../pages/gtm_modern_build_integration_pages";
import { ssoLitePages } from "../pages/sso_lite_pages";
import { routes } from '../routes';

class Main extends React.Component {
    environmentSelector = new EnvironmentSelector();
    config = this.environmentSelector.getConfig();
    prebidConfig = this.environmentSelector.getPrebidConfig();
    render() {
        return (
            <main style={{ 'marginTop': '8em' }}>
                <Routes>
                    <Route path={routes.html[100].path}
                        element={
                            <TagWriting100
                                env={this.config.env}
                                host={this.config.host}
                                version={this.config.version} />
                        }
                    />
                    <Route path={routes.html[110].path}
                        element={
                            <TagWriting110
                                env={this.config.env}
                                host={this.config.host}
                                version={this.config.version} />
                        }
                    />
                    <Route path={routes.html[120].path}
                        element={<TagWriting120 {...this.config} />}
                    />
                    <Route path={routes.html[130].path}
                        element={<TagWriting130 {...this.config} />}
                    />
                    <Route path={routes.html[140].path}
                        element={<TagWriting140 />}
                    />
                    <Route path={routes.html[141].path}
                        element={
                            <TagWriting141
                                env={this.config.env}
                                host={this.config.host}
                                localStorageTagKey={this.config.localStorageTagKey}
                                version={this.config.version} />
                        }
                    />
                    <Route path={routes.html[142].path}
                        element={<TagWriting142 />}
                    />
                    <Route path={routes.html[180].path}
                        element={
                            <TagWriting180
                                env={this.config.env}
                                host={this.config.host} />
                        }
                    />
                    {[
                        ...legacyReaderSinglePages,
                        ...legacyReaderMultiPages,
                    ].map(({ route, script }) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <ReaderSnippetTestPage
                                    config={this.config}
                                    script={script}
                                    title={route.title}
                                />
                            }
                        />
                    ))}
                    <Route path={routes.html[400].path}
                        element={<SlimReader400 {...this.config} />}
                    />
                    <Route path={routes.html[410].path}
                        element={<SlimSignal410 />}
                    />
                    <Route path={routes.html[500].path}
                        element={
                            <TagDeleting500
                                clientCookieSessionKey={this.config.clientCookieSessionKey}
                                clientCookieTagKey={this.config.clientCookieTagKey}
                                env={this.config.env}
                                host={this.config.host}
                                localStorageTagKey={this.config.localStorageTagKey}
                                version={this.config.version}
                                dmdCookies={this.config.dmdCookies}
                                dmdStorage={this.config.dmdStorage}
                            />
                        }
                    />
                    {parallelReaderPages.map(({ route, ...params }) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <ParallelSnippetTestPage
                                    env={this.config.env}
                                    {...params}
                                    title={route.title}
                                />
                            }
                        />
                    ))}
                    <Route path={routes.html[700].path}
                        element={<TagReading700 />}
                    />
                    <Route path={routes.html[701].path}
                        element={<TagReading701 />}
                    />
                    {prebidPages.map(({ route, script }) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <GTMSnippetTestPage
                                    config={this.prebidConfig}
                                    script={script}
                                    title={route.title}
                                />
                            }
                        />
                    ))}
                    {adobeAnalyticsIntegrationPages.map(({ route, script }) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <AdobeAnalyticsIntegrationPage
                                    config={this.config}
                                    script={script}
                                    title={route.title}
                                    modernBuildOnly={route.modernBuildOnly}
                                />
                            }
                        />
                    ))}
                    {googleUniversalAnalyticsIntegrationPages.map(({ route, script }) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <GoogleUniversalAnalyticsIntegrationPage
                                    config={this.config}
                                    script={script}
                                    title={route.title}
                                    modernBuildOnly={route.modernBuildOnly}
                                />
                            }
                        />
                    ))}
                    {googleAnalytics4IntegrationPages.map(({ route, script }) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <GoogleAnalytics4IntegrationPage
                                    config={this.config}
                                    script={script}
                                    title={route.title}
                                    modernBuildOnly={route.modernBuildOnly}
                                />
                            }
                        />
                    ))}
                    {gtmIntegrationPages.map(({ route, script, links }) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <GTMIntegrationPage
                                    config={this.config}
                                    script={script}
                                    title={route.title}
                                    links={links}
                                />
                            }
                        />
                    ))}
                    {gtmModernBuildIntegrationPages.map(({ route, script, links }) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <GTMModernBuildIntegrationPage
                                    config={this.config}
                                    script={script}
                                    title={route.title}
                                    links={links}
                                />
                            }
                        />
                    ))}
                    {ssoLitePages.map(({ route, script }) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <AIMTagSSOLitePage
                                    config={this.config}
                                    script={script}
                                    pageTitle={route.title}
                                />
                            }
                        />
                    ))}
                    <Route path={routes.root} element={<Dashboard />} />
                </Routes>
            </main >
        );
    }
}

export default Main;
