import qs from "qs";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const useQueryParamStore = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse query parameters
  const params = qs.parse(location.search.slice(1));

  // If version is not provided, set a default value.
  const defaultVersion = 'aim_tag';
  const defaultEnv = 'qa';
  params.version = params.version || defaultVersion;
  params.env = params.env || defaultEnv;

  const [state, setState] = useState(params);

  useEffect(() => {
    navigate(location.pathname + "?" + qs.stringify(state), { replace: true });
  }, [state, location.pathname, navigate]);

  return [state, setState] as const;
};
